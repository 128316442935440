import React, { useState, Fragment, createRef, useEffect } from 'react'
import { Button, CardBody, Row, Col } from 'reactstrap'
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import BootstrapTable from 'react-bootstrap-table-next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getPaginationArray } from '../../utils/pagination';
import '../../assets/styles/table.css';
import { v4 as uuidv4 } from 'uuid';
//import AxiosApi from 'AxiosApi';
import AxiosApi from '../../Api'

const columns = [

  {
    dataField: 'SkuNumber',
    text: 'SKU Number',
    headerClasses: 'border-0 font-weight-bold',
    classes: 'border-0 py-2 align-middle text-400',
    //sort: true,
    filter: textFilter({
      delay: 100,
    })
  },
  {
    dataField: 'MaterialDescription',
    text: 'Material Description',
    headerClasses: 'border-0 font-weight-bold',
    classes: 'border-0 py-2 align-middle text-400',
    //sort: true,
    filter: textFilter({
      delay: 100,
    })
  },
  {
    dataField: 'Brand',
    text: 'Brand',
    headerClasses: 'border-0 font-weight-bold',
    classes: 'border-0 py-2 align-middle text-400',
    //sort: true,
    filter: textFilter({
      delay: 100,
    })
  },
  {
    dataField: 'SubBrand',
    text: 'Sub Brand',
    headerClasses: 'border-0 font-weight-bold',
    classes: 'border-0 py-2 align-middle text-400',
    //sort: true,
    filter: textFilter(
      {
        delay: 100,
      }
    )
  },
  {
    dataField: 'ContainerSize',
    text: 'Container Size',
    headerClasses: 'border-0 font-weight-bold',
    classes: 'border-0 py-2 align-middle text-400',
    //sort: true,
    filter: textFilter({
      delay: 100,
    })
  },
  {
    dataField: 'SecondPack',
    text: 'SecondPack',
    headerClasses: 'border-0 font-weight-bold',
    classes: 'border-0 py-2 align-middle text-400',
    //sort: true,
    filter: textFilter({
      delay: 100,
    })
  },
]




const Sku = () => {
  const [skuData, setSkuData] = useState([])
  const dataMap = (element) => ({
    ...element,
    SkuNumber: element?.['F7'][0],
    MaterialDescription: element?.['F7'][1],
    Brand: element?.["F20"],
    SubBrand: element?.['F21'],
    ContainerSize: element?.['F32'],
    SecondPack: element?.['F30'],
    id: uuidv4(),


  });
  const config = {
    method: 'GET',
    url: process.env.REACT_APP_API_ROOT_URL_PREFIX + '/getskulist'
    ,
  };
  useEffect(() =>
    AxiosApi(config)
      .then((response) => {
        console.log(response.data.recordsets[0])
        setSkuData(response?.data?.recordsets[0])
      })
      .catch((error) => {
        console.log(error);
      }), []

  )
  const dataa = skuData?.map(dataMap);
  return (
    <SKUTable dataa={dataa} />
  )
}
const SKUTable = ({ dataa }) => {
  let table = createRef();
  const [skuNumber, setSkuNumber] = useState([]);
  const [result, setResult] = useState([]);
  const onSelect = (row) => {
    if (!result?.includes(row.id)) {
      setResult(result => [...result, row.id]);
      setSkuNumber(skuNumber => [...skuNumber, row['SkuNumber']]);
    }
    else {
      setResult((result) => result?.filter((item) => item !== row.id))
      setSkuNumber((skuNumber) => skuNumber.filter((item) => item !== row.SkuNumber))
    }

  };
  const selectRow = {
    mode: 'checkbox',// single row selection
    onSelect: onSelect
  };

  return (
    <>


      <BootstrapTable
        ref={table}
        bootstrap4
        keyField="id"
        data={dataa}
        columns={columns}
        bordered={false}
        classes="table-dashboard table-striped table-sm fs--1 border-bottom border-200 mb-0 fontt table-dashboard-th-nowrap"
        rowClasses="btn-reveal-trigger text-400 border-top  border-200"
        headerClasses="bg-200 text-700 border-y font-weight-bold border-200"
        filter={filterFactory()}
        selectRow={selectRow}


        pagination={paginationFactory()}// {...paginationTableProps}
      />
      <div style={{ textAlign: 'left' }}>
        <h3>Selected Values:</h3>
        <div className="row">
          <div className="col-auto">
            <label className="form-label">SKU Number</label>
          </div>
          <div className="col">
            <div className="form-group">
              <input type="text" className="form-control form-control-sm" value={skuNumber} />
            </div>
          </div>
          {/* <div className="col-auto">
                                <label className="form-label">Material Description </label>
                            </div>
                            <div className="col">
                                <div className="form-group">
                                    <input type="text" className="form-control form-control-sm" value={materialDescription} />
                                </div>
                            </div> */}
        </div>
      </div>
    </>
  )
}
export default Sku;