import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useUnsavedChangesWarning = (
  message = "Are you sure you want to discard changes?"
) => {
  const [isDirty, setDirty] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isDirty) {
        const e = event || window.event;
        // For modern browsers
        e.returnValue = message;
        // For old IE
        return message;
      }
    };

    const handleNavigate = (event) => {
      if (isDirty && !window.confirm(message)) {
        event.preventDefault();
      }
    };

    // Detecting browser closing
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Listen for navigation events
    window.addEventListener("popstate", handleNavigate);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handleNavigate);
    };
  }, [isDirty]);

  return [() => setDirty(true), () => setDirty(false)];
};

export default useUnsavedChangesWarning;
