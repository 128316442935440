import axios from "axios";
import React, { useState } from "react";
import ConfirmAlert from "./common/ConfirmAlert";
import Swal from 'sweetalert2';
// var generator = require('generate-password'); anas
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
const ForgotPassword = () => {
  const [email, setEmail] = useState();
  const [soldTo, setSoldTo] = useState();
  const [isCorrectEmail, setIsCorrectEmail] = useState(2);
  const [isCorrectSoldTo, setIsCorrectSoldTo] = useState(2);
  const [emailError, setEmailError] = useState();
  const [successData, setSuccessData] = useState(false);
  const [password, setPassword] = useState('');

  var userId;


  const checkSoldTo = (value) => {
    if (value.length >= 5) {
      axios.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/fetchUserId?email=${email}`)
        .then((response) => {
          userId = response?.data?.recordset[0]?.userID
          if (response?.data?.recordset?.length == 1)
            setIsCorrectEmail(1);
          else
            setIsCorrectEmail(0);
          return axios.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/checkSoldTo?userId=${userId}`)
        })

        .then((response) => {

          if (response?.data?.recordset?.length == 1 && response?.data?.recordset[0]?.soldTo == value)
            setIsCorrectSoldTo(1);
          else
            setIsCorrectSoldTo(0);
        })
    }

  }

  const onSubmit = async (e) => {
    //setSuccessData(true);
    e.preventDefault();

    // var pwd = generator.generate({
    //   length: 36,
    //   lowercase: true,
    //   uppercase: true,
    //   numbers: true,
    //   symbols: false
    // });
    function generate(options) {
      const defaultOptions = {
        length: 36,
        lowercase: true,
        uppercase: true,
        numbers: true,
        symbols: false,
      };

      const mergedOptions = { ...defaultOptions, ...options };

      let characters = '';

      if (mergedOptions.lowercase) characters += 'abcdefghijklmnopqrstuvwxyz';
      if (mergedOptions.uppercase) characters += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      if (mergedOptions.numbers) characters += '0123456789';
      if (mergedOptions.symbols) characters += '!@#$%^&*()-_=+[]{}|;:\'",.<>?/';

      let result = '';
      const mandatoryTypes = [];

      if (mergedOptions.lowercase) {
        result += characters.charAt(Math.floor(Math.random() * 26));
        mandatoryTypes.push('lowercase');
      }

      if (mergedOptions.uppercase) {
        result += characters.charAt(26 + Math.floor(Math.random() * 26));
        mandatoryTypes.push('uppercase');
      }

      if (mergedOptions.numbers) {
        result += characters.charAt(52 + Math.floor(Math.random() * 10));
        mandatoryTypes.push('numbers');
      }

      if (mergedOptions.symbols) {
        result += characters.charAt(62 + Math.floor(Math.random() * 14));
        mandatoryTypes.push('symbols');
      }

      for (let i = mandatoryTypes.length; i < mergedOptions.length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
      }
      result = result.split('').sort(() => Math.random() - 0.5).join('');
      return result;
    }

    var pwd;
    const generatePassword = () => {
      pwd = generate({
        length: 36,
        lowercase: true,
        uppercase: true,
        numbers: true,
        symbols: false,
      });
      setPassword(pwd);
      // console.log(pwd, 'password');
    };

    // let pwd = 123;
    generatePassword();
    await axios.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/sendEmail', { email: email, password: pwd })
      .then((response) => {
        console.log(response);
      }).catch((e) => {
        console.log(e)
      })
    await axios.put(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/updateLoginMaster', { email: email, password: pwd })
      .then((response) => {
        console.log(response); setSuccessData(response?.data?.response == true ? true : false);
        toast.success('Password sent to your mail.', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000, // Close the toast after 3000 milliseconds (3 seconds)
        });
        hideAlert()
      })
    //New Password
  }
  async function hideAlert() {
    window.location.reload();
  }
  // const isValidPassword = (value) => {
  //   var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
  //   if (value.match(passw))
  //     setPasswordError('Valid Password')
  //   else
  //     setPasswordError('Invalid Password')

  // }
  const isValidEmail = (value) => {
    var validRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (value.match(validRegex))
      setEmailError('Valid Email')
    else
      setEmailError('Invalid Email')

  }
  return (
    <>
      <div className="container-fluid px-1 py-5 mx-auto">
        <div className="row d-flex justify-content-center">
          <div className="col-xl-7 col-lg-8 col-md-9 col-11 text-center">
            <h3>Change Password</h3>
            {/* <p className="blue-text">Just answer a few questions<br/> so that we can personalize the right experience for you.</p> */}
            <div className="card" style={{ padding: "30px 40px", borderRadius: "5px" }}>
              {/* <h5 className="text-center mb-4">Powering world-className companies</h5> */}
              <form className="form-card" onsubmit="event.preventDefault()">
                <div className="row justify-content-between text-left">
                  {/* <div className="form-group col-sm-6 flex-column d-flex"> */}
                  {" "}
                  <label className="form-control-label px-3">
                    Email <span className="text-danger"> *</span>
                    {isCorrectEmail === 0 ? <font style={{ fontSize: "12px", color: 'red ' }}>Email not correct</font> : null}
                    {isCorrectEmail === 1 ? <font style={{ fontSize: "12px", color: 'green' }}>Correct Email</font> : null}
                    <br />
                    {emailError === 'Invalid Email' ? <font style={{ fontSize: "12px", color: 'red ' }}>{emailError}</font> : null}
                    {emailError === 'Valid Email' ? <font style={{ fontSize: "12px", color: 'green' }}>{emailError}</font> : null}
                  </label>
                  <input
                    type="text"
                    id="email"
                    className='form-control'
                    name="email"
                    placeholder=""
                    onChange={(e) => { setEmail(e.target.value); isValidEmail(e.target.value) }}
                    onblur="validate(3)"
                  />{" "}
                  {/* </div> */}
                </div>



                <div class="row justify-content-end">
                  <div class="form-group col-sm-6">
                    {" "}
                    <button type="submit" class="btn-block btn-primary" disabled={isCorrectSoldTo === 0 || isCorrectEmail === 0 || emailError == 'Invalid Email'} onClick={onSubmit}>
                      Change Password
                    </button>{" "}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />

    </>
  );
};

export default ForgotPassword;
