import React from 'react';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';


export default async function ConfirmAlert(props) {
	const confirmBtnText = props.confirmBtnText || 'Yes';
	const { type } = props;
	const result = await Swal.fire({
		icon: type, // type is used for the icon
		title: props.title,
		text: props.body,
		showCancelButton: props.cancelButton !== false,
		showCloseButton: true,
		confirmButtonText: confirmBtnText,
		cancelButtonText: 'No',
	});

	if (result.isConfirmed) {
		props.confirm();
	} else if (result.dismiss === Swal.DismissReason.cancel) {
		props.hideAlert();
	}
};

// export default function ConfirmAlert(props) {
// 	const { type } = props;
// 	const confirmBtnText = props.confirmBtnText || 'Yes';

// 	return (
// 		<SweetAlert
// 			style={{ fontSize: '1vw' }}
// 			type={type}
// 			showCancel={props.cancelButton !== false}
// 			showCloseButton
// 			confirmBtnText={confirmBtnText}
// 			cancelBtnText="No"
// 			title={props.title}
// 			onConfirm={() => props.confirm()}
// 			onCancel={() => props.hideAlert()}
// 		>
// 			{props.body}
// 		</SweetAlert>
// 	);

// }
// ConfirmAlert.propTypes = {
// 	type: PropTypes.string.isRequired,
// 	confirmBtnText: PropTypes.string.isRequired,
// 	cancelButton: PropTypes.bool.isRequired,
// 	title: PropTypes.string.isRequired,
// 	confirm: PropTypes.func.isRequired,
// 	hideAlert: PropTypes.func.isRequired,
// 	body: PropTypes.string.isRequired,
// };
