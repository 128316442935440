// navbar4 mdm
import React, { useEffect, useState } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import dataService from '../../services/dataService';
import defaultProfilePhoto from '../../assets/images/profile.jpg';
import { loginRequest } from '../../utils/authConfig';
import FeatherIcon from 'feather-icons-react';
import Logo from '../../assets/images/abi_logo.png';
import { NavLink } from 'react-router-dom';
import CommonNavbar from '../../common/Navbar/CommonNavbar';

const NavBar = () => {
  const isAuthenticated = useIsAuthenticated();
  const [name, changeName] = useState('');
  const [email, changeEmail] = useState('');
  const { accounts, instance } = useMsal();
  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: '/',
    });
  };
  const [isDropDownOpen, setisDropDownOpen] = useState(false);
  const [isDropDownOpen1, setisDropDownOpen1] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const togglingDropDown = () => {
    setisDropDownOpen(!isDropDownOpen);
  };
  const togglingDropDown1 = () => {
    setisDropDownOpen1(!isDropDownOpen1);
  };
  useEffect(() => {
    if (accounts.length !== 0) {
      changeName(accounts[0].name);
      changeEmail(accounts[0].username);
    } else {
      changeName('');
      changeEmail('');
    }
  }, [accounts]);
  useEffect(() => {
    // console.log('Account: ', accounts[0]);
    if (accounts.length > 0) {
      // console.log('starting below');
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then((response) => {
          // console.log(response);
          dataService
            .getMSGraphPhoto(response.accessToken)
            .then((image) => {
              if (image.type === 'image/jpeg') setProfilePhoto(image);
            })
          // .catch((err) => console.log(err));
        })
        .catch((err) => {
          console.log(`Error occured while acquiring token: ${err}`);
        });
    }
  }, [accounts]);
  return (
    <>

      <div className='d-flex justify-content-between align-items-center px-5 py-2'
        style={{ backgroundColor: "black" }}>

        {/* navbar componenet */}
        <CommonNavbar
          images={[Logo]}
          avatar={profilePhoto ? URL.createObjectURL(profilePhoto) : defaultProfilePhoto}
          appVersion={process.env.REACT_APP_VERSION || "PROD 5.1"}
          username={accounts.length > 0 ? name : "Hi, Guest"}
          useremail={accounts.length > 0 ? email : null}
          support="mailto:DL-COEAutomationRM@AnheuserBuschInBev.onmicrosoft.com"
        />

        {/* <NavLink to="/">
          <img
            src={Logo}
            alt="Brand Logo" width="160" padding="10px"
          />
        </NavLink> */}

        {/* <div className="dropdown">

          <a className="d-flex justify-content-between align-items-center" onClick={togglingDropDown}>
            <img
              className="avatar"
              src={profilePhoto ? URL.createObjectURL(profilePhoto) : defaultProfilePhoto}
              alt=""
            />

            <div className="ml-2">
              <span className="text-default">
                <strong style={{ color: "#f1c40f" }}>{accounts.length > 0 ? name : ''}</strong>
              </span>
              <span>
                <FeatherIcon icon="chevron-down" size={14} />
              </span>
              <small className="d-block mt-1" style={{ color: "white " }}>
                {accounts.length > 0 ? email : ''}
              </small>

              <small className="d-block mt-1" style={{ color: "white " }}>
                {window.location.pathname === '/mdm'
                  ? `Role: ${localStorage.getItem('role')}`
                  : ""}
              </small>

            </div>
          </a>

          {isDropDownOpen && (
            <div
              className="dropdown-menu dropdown-menu-right dropdown-menu-arrow show"
              style={{ cursor: 'pointer' }}
            >
              <a
                className="dropdown-item text-left"
                href="mailto:DL-COEAutomationRM@AnheuserBuschInBev.onmicrosoft.com"
              >
                Support
              </a>
              <a className="dropdown-item text-left" onClick={handleLogout}>
                Sign out
              </a>
            </div>
          )}
        </div> */}
      </div >

      <nav
        className="navbar navbar-expand-md navbar-dark"
        style={{ background: 'linear-gradient(90deg, rgb(227, 175, 50) 0%, rgb(244, 224, 15) 100%)', height: '10px', paddingLeft: '10vw' }}
      >
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <ul className="navbar-nav mr-auto">
            <div>
              <li className="nav-item">
                <NavLink
                  to="/mdm"
                  style={{ color: "black", textDecoration: "none" }}
                  className={({ isActive }) => `${isActive ? "font-weight-bold" : ""}`}
                >
                  New Ticket
                </NavLink>
              </li>
            </div>
          </ul>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav mr-auto">
              <div>
                <li className="nav-item">
                  <NavLink
                    style={{ color: "black", textDecoration: "none" }}
                    to={`/myTickets`}
                    className={({ isActive }) => `${isActive ? "font-weight-bold" : ""}`}
                  >
                    My Tickets
                  </NavLink>
                </li>
              </div>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavBar;
