import { useState, useEffect } from 'react'
import axios from 'axios'

import { urls } from '../utils/apiConfigs'
import { graphConfig } from '../utils/authConfig'

export default {
  getMedicalReps: async function () {
    try {
      const response = await axios.get(urls.getMedicalRepsURL)
      return response.data
    } catch (error) {
      throw error
    }
  },

  getStates: async function () {
    try {
      const response = await axios.post(urls.getStatesURL)
      return response.data
    } catch (error) {
      throw error
    }
  },

  getCities: async function (stateCode) {
    try {
      const response = await axios.post(urls.getCitiesURL, {
        stateCode: stateCode,
      })
      return response.data
    } catch (error) {
      throw error
    }
  },

  getMSGraphPhoto: async function (accessToken) {
    try {
      const headers = new Headers()
      const bearer = `Bearer ${accessToken}`

      headers.append('Authorization', bearer)

      const options = {
        method: 'GET',
        headers: headers,
      }

      // console.log('TRYING TO GET PROFILE PHOTO')

      return fetch(graphConfig.graphProfilePhotoEndpoint, options)
        .then((response) => response.blob())
        // .catch((error) => console.log(error))
    } catch (error) {
      throw error
    }
  },
}
export const getSysId = async function (user) {
  try {
    let req = await axios.get(`${urls.getSysID}${user}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('snowtoken')}`
      }
    });
    // console.log('sucess1');
    return req.data;
  } catch (error) {
    // console.log(error);
  }

};
export const getIncidentSysId = async function (incidentNumber) {
  try {
    let req = await axios.get(`${urls.getIncidentSysID}${incidentNumber}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('snowtoken')}`
      }
    });
    // console.log('sucess2');
    return req.data;
  } catch (error) {
    // console.log(error);
  }
};
export const uploadFile = async function (formData) {

  try {
    // console.log("hi")
    let req = await axios.post(urls.UploadFile, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        'Authorization': `Bearer ${localStorage.getItem('snowtoken')}`,
      },

    },);
    // console.log('sucess3');
    return;
  } catch (error) {
    // console.log('e')
    // console.log(error);
  }
};


export const pushSql = async function (data) {
  try {
    let req = await axios.post(urls.pushSQL, data, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('snowtoken')}`
      }
    });
    // console.log('sucess4');
    return req.data;
  } catch (error) {
    // console.log(error);
  }
};
export const raiseTicket = async function (data) {
  try {
    const response = await axios.post(urls.createIncident, data, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('snowtoken')}`
      }
    });
    // console.log('sucess5');
    return response.data;
  } catch (error) {
    return error.response;
  }
};

