import React, { useEffect, useState } from 'react';
import { AiFillFileAdd } from 'react-icons/ai';
import { Navigate, Link } from 'react-router-dom';
import { Menu, Button, Text, rem, Burger, Divider, Badge } from '@mantine/core';
// import {
//     IconInfoCircle, IconClick, IconDownload, IconSettings,
//     IconSearch,
//     IconPhoto,
//     IconMessageCircle,
//     IconTrash,
//     IconArrowsLeftRight,
//     IconSetting
// } from '@tabler/icons-react';
import {
  IconInfoCircle, IconClick, IconDownload, IconSettings,
  IconSearch,
  IconPhoto,
  IconMessageCircle,
  IconTrash,
  IconArrowsLeftRight,
  IconLogout,
  IconHelp,
  IconUserPlus,
  IconSetting,
  IconHome,
  IconMenu2
} from '@tabler/icons-react';
import { Avatar } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import CommonHoverCard from './CommonHoverCard';


const CommonNavbar = ({ images, avatar, appVersion, username, useremail, support = '/contactus' }) => {
  const { accounts, instance } = useMsal();

  const [userRole, setUserRole] = useState("");
  const [opened, setOpened] = useState(false);
  const togglingDropDown = () => { setOpened(!opened) };
  const handleLogout = () => {
    instance.logout({
      postLogoutNavigateUri: '/',
    });
    localStorage.removeItem("username");
    localStorage.removeItem("role");
    localStorage.removeItem("name");
    localStorage.removeItem("ooc_user_role");
    localStorage.removeItem("userRole");
    localStorage.removeItem("isReady");
    //  localStorage.clear();
    <Navigate to={{ pathname: "/" }} />
  };

  const getOOCUserRoles = {
    1: "Requestor",
    2: "Admin",
    3: "Admin & Approver",
    4: "Approver",
  }

  const getRetPackUserRoles = {
    1: "Admin",
    2: "Customer External",
    3: "Super Admin	",
    4: "Customer InterCompany",
    5: "Gel Reporting	",
  }


  const determineRedirectPath = () => {
    const currentPathname = window.location.pathname;
    if (currentPathname === '/') {
      return localStorage.getItem('role');
    } else if (currentPathname === '/cq-portal-home' || currentPathname === '/add-complaint' || currentPathname === '/complaints') {
      return localStorage.getItem('role').replace('_', ' ').toUpperCase();
    } else if (currentPathname === '/one-order-change-homepage') {
      return getOOCUserRoles[localStorage.getItem('ooc_user_role')];
    } else if (currentPathname === '/retPack') {
      return getRetPackUserRoles[localStorage.getItem('userRole')]
    } else if (currentPathname === '/mdm' || currentPathname === '/myTickets') {
      return localStorage.getItem('role').replace('_', ' ').toUpperCase();
    } else if (currentPathname === '/register') {
      return localStorage.getItem('role').replace('_', ' ').toUpperCase();
    }


    return '';
  };

  useEffect(() => {
    const user = determineRedirectPath();

    if (user) {
      setUserRole(user);
    }
  }, []);


  return (


    <div className='d-flex justify-content-between align-items-center px-2 py-2 w-100'>

      <div className='d-flex'>
        <div>
          {
            images?.map((image) => {
              return (
                <Link to="/">
                  <img
                    src={image}
                    style={{ height: "2rem", width: "auto", marginRight: "10px" }}
                  />
                </Link>
              )
            })
          }
        </div>

      </div>

      <div className='d-flex justify-content-center  align-items-center'>

        <CommonHoverCard
          userImg={avatar}
          username={username}
          useremail={useremail}
          appVersion={appVersion}
          userRole={userRole ? `${userRole}` : ""}
        />
        <div className="d-flex justify-content-center align-items-center" >
          <Menu shadow="md" width={150} opened={opened} onChange={setOpened} offset={0} zIndex={10000}>
            <Menu.Target>
              {/* <Button onClick={() => togglingDropDown()} variant="filled" color="yellow" size="xs" radius="xs" >
                <IconSettings size={14} color="black" /> */}
              {/* {opened ? <IconMenu2 size={14} color="black" /> : <IconSettings size={14} color="black" />} */}
              <Burger opened={opened} onClick={() => togglingDropDown()} aria-label="Toggle navigation" color="yellow" />
              {/* </Button> */}
            </Menu.Target>

            <Menu.Dropdown>

              <Menu.Item zIndex={1000} leftSection={<IconHome style={{ width: rem(14), height: rem(14) }} />}>
                <Link to="/" className="dropdown-item text-left">
                  Home
                </Link>
              </Menu.Item>

              <Menu.Item leftSection={<IconHelp style={{ width: rem(14), height: rem(14) }} />}  >
                <Link to={support}
                  className="dropdown-item text-left">
                  Support
                </Link>
              </Menu.Item>

              {
                localStorage.getItem('role') === 'CX_Agent' &&
                <Menu.Item leftSection={<IconUserPlus style={{ width: rem(14), height: rem(14) }} />}>
                  <Link to="/register" className="dropdown-item text-left">
                    Register
                  </Link>
                </Menu.Item>
              }
              <Menu.Divider />
              <Menu.Item leftSection={<IconLogout style={{ width: rem(14), height: rem(14) }} />} color="pink"
                onClick={handleLogout}>
                {/* <Button  className="dropdown-item text-left m-0 p-0" > */}
                Sign out
                {/* </Button> */}
              </Menu.Item>

            </Menu.Dropdown>
          </Menu >

        </div>

      </div>

    </div>


  )
}

export default CommonNavbar;