import { Navbar, Nav } from 'react-bootstrap';
import React, { Component } from "react";
import "../style.css";
import { Link } from 'react-router-dom';

export default class Navigation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userRole: this.props.userRole,
            homeCss: '',
            dashboardCss: '',
            archiveCss: '',
            uploadCss: '',
            mgmCss: '',
        };
    }
    componentDidMount() {
        if (window.location.pathname === '/') {
            this.setState({
                homeCss: 'abc-2',
                dashboardCss: 'abc',
                archiveCss: 'abc',
                mgmCss: 'abc',
            })
        }
        else if (window.location.pathname === '/dashboard') {
            this.setState({
                homeCss: 'abc',
                dashboardCss: 'abc-2',
                archiveCss: 'abc',
                mgmCss: 'abc',
                uploadCss: 'abc'
            })
        }
        else if (window.location.pathname === '/userMgmt') {
            this.setState({
                homeCss: 'abc',
                dashboardCss: 'abc',
                archiveCss: 'abc',
                mgmCss: 'abc-2',
                uploadCss: 'abc'
            })
        }
        else if (window.location.pathname === '/archive') {
            this.setState({
                homeCss: 'abc',
                dashboardCss: 'abc',
                archiveCss: 'abc-2',
                mgmCss: 'abc',
                uploadCss: 'abc'
            })
        }
        else if (window.location.pathname === '/upload2') {
            this.setState({
                homeCss: 'abc',
                dashboardCss: 'abc',
                archiveCss: 'abc',
                mgmCss: 'abc',
                uploadCss: 'abc-2'
            })
        }
        else {
            this.setState({
                homeCss: 'abc',
                dashboardCss: 'abc',
                archiveCss: 'abc',
                mgmCss: 'abc',
                uploadCss: 'abc'
            })
        }
    }

    render() {
        return (
            <Navbar style={{ paddingLeft: '35px', paddingRight: '35px' }} collapseOnSelect expand='md' className="color-nav">
                <Navbar.Toggle aria-controls='rsponsive-navbar-nav' />
                <Navbar.Collapse id='responsive-navbar-nav'>
                    <Nav className='color-font'>
                        <Link className="linkText" to='/one-order-change-homepage'>
                            Home
                        </Link>
                        <Link className="linkText" to='/dashboard'>
                            Dashboard
                        </Link>
                        {(this.state.userRole == 2 || this.state.userRole == 3) &&
                            <Link className="linkText" to='/userMgmt'>
                                Administration
                            </Link>}
                        {(this.state.userRole == 2 || this.state.userRole == 3) &&
                            <Link className="linkText" to='/archive'>
                                Archives
                            </Link>}
                        <Link className="linkText" to='/upload2'>
                            Mass Upload
                        </Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

