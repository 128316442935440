import { HoverCard, Avatar, Text, Group, Anchor, Stack, Badge } from '@mantine/core';

function CommonHoverCard({ userImg, username, useremail, userRole, appVersion }) {
    return (
        <Group justify="center">
            <HoverCard width={380} shadow="md" withArrow openDelay={0} closeDelay={400} >
                <HoverCard.Target>
                    <Avatar src={userImg} radius="xl" />
                </HoverCard.Target>
                <HoverCard.Dropdown>
                    <Group>
                        <Avatar src={userImg} radius="xl" />
                        <Stack gap={5}>
                            <Text size="sm" fw={700} style={{ lineHeight: 1 }}>
                                {username}
                            </Text>
                            <Text size="sm" fw={600} style={{ lineHeight: 1 }}>
                                {useremail}
                            </Text>
                        </Stack>
                    </Group>

                    <Group mt="md" gap="sm">

                        <Badge color="green" autoContrast >{appVersion}</Badge>
                        <Badge color="yellow" autoContrast >{userRole} </Badge>

                    </Group>

                </HoverCard.Dropdown>
            </HoverCard>
        </Group>
    );
}

export default CommonHoverCard;