import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "bootstrap/dist/css/bootstrap.css";
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {
  dateFilter,
  textFilter,
  Comparator,
  //selectFilter,
} from "react-bootstrap-table2-filter";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import "../style.css";
//import AxiosApi from "AxiosApi";
import ClipLoader from "react-spinners/ClipLoader";
import AxiosApi from '../../../Api'

export class Dashbord extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filesData: [],
      nonSelected: [],
      selected: [],
      isHidden: true,
      approver: this.props.emailid,
      userRole: this.props.userRole,
    };
  }
  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden,
    });
  }

  pullData() {
    this.setState({ loading: true });
    AxiosApi.get(process.env.REACT_APP_API_ROOT_URL_PREFIX + "/api/getallretPack", {
      headers: {
        requesterEmail: localStorage.getItem("username"),
        userRole: localStorage.getItem("role")

      }
    },).then((response) => {
      console.log(response.data)
      this.setState({
        filesData: response.data,
      });
      this.handleUnSelect(response.data);
    })
      .catch(error => {
        console.error('There was an error in fetching data !', error);
      })
      .finally(() => {
        this.setState({
          loading: false // stop spinner (in response/error)
        });
      })
  }

  componentDidMount() {
    this.pullData();
  }

  handleUnSelect = (data) => {
    var ids = data.map(r => {
      if (r.Bot_status === "" || r.Bot_status === "In Progress") return r.Id;
      else return ""
    });

    console.log(ids);
    this.setState(() => ({
      nonSelected: ids,
    }));
    console.log(+ " " + ids);
  };

  handleOnSelect = (row, isSelect) => {

    if (isSelect) {

      this.setState(() => ({
        selected: [...this.state.selected, row.Id],

      }));
      console.log(this.state.selected + "" + row.Id)
    } else {
      this.setState(() => ({
        selected: this.state.selected.filter((x) => x !== row.Id),
      }));
      console.log(this.state.selected.filter((x) => x !== row.Id))
    }

  };

  handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map((r) => r.Id);
    if (isSelect) {
      this.setState(() => ({
        selected: ids,
      }));
      console.log(this.state.selected + " " + ids)
    } else {
      this.setState(() => ({
        selected: [],
      }));
      console.log(this.state.selected)
    }
  };

  render() {
    const MyExportCSV = (props) => {
      const handleClick = () => {
        props.onExport();
      };
      return (
        <div className="div_export">
          <button type="button" className="fresh-button" onClick={handleClick}>Export to CSV</button>
        </div>
      );

    }
    const sizePerPageList = [{
      text: '10', value: 10
    }, {
      text: '25', value: 25
    }, {
      text: '30', value: 30
    }, {
      text: '50', value: 50
    }, {
      text: '100', value: 100
    }, {
      text: 'All', value: this.state.filesData.length
    }];

    const selectOptions = {
      "Equals": "Equals",

    };
    const selectRow = {
      mode: "checkbox",
      clickToSelect: true,
      clickToEdit: true,
      selected: this.state.selected,
      nonSelectable: this.state.nonSelected,
      onSelect: this.handleOnSelect,
      onSelectAll: this.handleOnSelectAll,
      // bgColor: "",
    };

    const columns = [
      {
        dataField: "Id",
        text: "ID",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        sort: true,
        editable: false,
        headerClasses: 'classheaderid',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "CreatedOn",
        text: "From Date",
        filter: dateFilter({
          delay: 600, placeholder: 'custom placeholder', className: ' filter_style',
          dateStyle: { margin: '0px', width: '100%', fontSize: '0.75rem' }, comparatorClassName: 'custom-comparator-class', withoutEmptyComparatorOption: true, comparator: Comparator.GE, defaultValue: { comparator: Comparator.GE }
        }),
        headerClasses: 'classheaderdate',
        editable: false,
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },

      {
        dataField: "CreatedOn",
        text: "To Date",
        filter: dateFilter({
          delay: 600, placeholder: 'custom placeholder', className: '',
          dateStyle: { margin: '0px', width: '100%', fontSize: '0.75rem' }, comparatorClassName: 'custom-comparator-class', withoutEmptyComparatorOption: true, comparator: Comparator.LE, defaultValue: { comparator: Comparator.LE }
        }),
        editable: false,
        headerClasses: 'classheaderdate',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Location",
        text: "Location",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },

      // new columns----------------------------------------------------------------------

      {
        dataField: "ERP_Order",
        text: "ERP Order",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Sent_To_KC_group",
        text: "Sent To KC group",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Overall_status",
        text: "Overall status",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "BOT_Status",
        text: "BOT Status",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        editable: true,
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Origin",
        text: "Origin",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Sales_Org",
        text: "Sales Org",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Sold_To",
        text: "Sold To",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Customer_Name",
        text: "Customer Name",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "word-wrap": "break-word", "white-space": "pre-wrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Customer_Address",
        text: "Customer Address",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "word-wrap": "break-word", "white-space": "pre-wrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Seal_Number_For_US",
        text: "Seal Number For US",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Vessel_For_UK_And_Germany",
        text: "Vessel For UK And Germany",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Container_Number/Truck",
        text: "Container_Number/Truck",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Date_info_receipt",
        text: "Date info receipt",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Container_Type",
        text: "Container Type",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Plant",
        text: "Plant",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Plant_code",
        text: "Plant code",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Budnet_number_for_US",
        text: "Budnet number for US",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "BL_for_UK",
        text: "BL for UK",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Port_of_Discharge/arrival",
        text: "Port_of_Discharge/arrival",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "BOL_number_for_US",
        text: "BOL number for US",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Empty_6L",
        text: "Empty6L",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Empty_10L",
        text: "Empty10L",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Empty_20L_Bellevue",
        text: "Empty_20L_Bellevue",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Empty_30L",
        text: "Empty30L",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Empty_30L_Hoegaarden",
        text: "Empty_30L_Hoegaarden",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Empty_50L",
        text: "Empty_50L",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Empty_19,5L",
        text: "Empty_19,5L",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Empty_20L",
        text: "Empty_20L",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Faulty_20L",
        text: "Faulty_20L",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Faulty_30L_Stella",
        text: "Faulty_30L_Stella",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Faulty_30L_Hoegaarden",
        text: "Faulty_30L_Hoegaarden",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Faulty_50L",
        text: "Faulty_50L",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Faulty_19,5",
        text: "Faulty_19,5",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      //-----------------------------------------
      {
        dataField: "Empty_6L_Avizo",
        text: "Empty_6L_Avizo",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Empty_10L_Avizo",
        text: "Empty_10L_Avizo",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Empty_30L_Avizo",
        text: "Empty_30L_Avizo",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Empty_50L_Avizo",
        text: "Empty_50L_Avizo",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Faulty_6L_Avizo",
        text: "Faulty_6L_Avizo",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Faulty_10L_Avizo",
        text: "Faulty_10L_Avizo",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Faulty_30L_Avizo",
        text: "Faulty_30L_Avizo",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Faulty_50L_Avizo",
        text: "Faulty_50L_Avizo",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Pallets",
        text: "Pallets",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Empty_10L_Avizo_35294",
        text: "Empty_10L_Avizo_35294",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Empty_30L_Avizo_29332",
        text: "Empty_30L_Avizo_29332",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Empty_50L_Avizo_15614",
        text: "Empty_50L_Avizo_15614",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Faulty_30L_Avizo_2",
        text: "Faulty_30L_Avizo_2",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Faulty_50L_Avizo_3",
        text: "Faulty_50L_Avizo_3",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Draft_Wooden_Pallets_Underloaded",
        text: "Draft_Wooden_Pallets_Underloaded",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Empty_20_L",
        text: "Empty_20_L",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Empty_30L2",
        text: "Empty_30L2",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Empty_50L3",
        text: "Empty_50L3",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Plastic_Pallets_Not_Underloaded",
        text: "Plastic Pallets Not Underloaded",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Plastic_Pallets_Underloaded",
        text: "Plastic Pallets Underloaded",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Plastic_Separators",
        text: "Plastic Separators",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Plastic_Bulkheads",
        text: "Plastic Bulkheads",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Draft_Wooden_Pallets_Not_Underloaded",
        text: "Draft_Wooden_Pallets_Not_Underloaded",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Total_Quantity",
        text: "Total Quantity",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Shipment_Reference",
        text: "Shipment Reference",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Shipping_Line",
        text: "Shipping Line",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "ETA/ETD",
        text: "ETA/ETD",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Last_Free_Storage",
        text: "Last Free Storage",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Last_Free_Demurrage",
        text: "Last Free Demurrage",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Last_Free_Detention",
        text: "Last Free Detention",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Customs_Clearance_Date",
        text: "Customs Clearance Date",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Requested_Delivery_Date_From_KC_Group",
        text: "Requested Delivery Date From KC Group",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "In",
        text: "In",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Unloading_Date",
        text: "Unloading Date",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "OUT",
        text: "Out",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Reused",
        text: "Reused",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Transport",
        text: "Transport",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Storage_Days_To_Pay",
        text: "Storage Days To Pay",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Demurrage_Days_To_Pay",
        text: "Demurrage Days To Pay",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Detention_Days_To_Pay",
        text: "Detention Days To Pay",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Storage_Costs",
        text: "Storage Costs",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Demurrage_Costs",
        text: "Demurrage Costs",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Dettention_Costs",
        text: "Dettention Costs",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Credit_Memo_Number",
        text: "Credit Memo Number",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "PO_Number",
        text: "PO Number",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "CN_Number",
        text: "CN Number",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "200px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Comments",
        text: "Comments",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        // style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "400px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },
      {
        dataField: "Request_No",
        text: "Request No",
        filter: textFilter({ className: "filter_style", style: { fontSize: '0.75rem' } }),
        headerClasses: 'class-headers',
        style: { "white-space": "nowrap" },
        headerStyle: () => {
          return { minWidth: "500px", backgroundColor: "#f1c40f", border: '1px solid rgba(128, 128, 128, 0.5)' };
        },
      },

    ];

    return (

      <div className="Dashbored_div textAlignCenter">
        {/* <div>Total Record count: {this.state.filesData.length}</div> */}
        <ToolkitProvider
          bootstrap4
          keyField="Id"
          data={this.state.filesData}
          columns={columns}

          exportCSV={{
            fileName: "Dashboard.csv",
            exportAll: false,
            onlyExportFiltered: true,
            onlyExportSelection: true,
          }}
        >{
            props => (
              <div><div style={{ float: 'left', margin: '25px', fontSize: '12px' }}>Total number of records: {this.state.filesData.length}</div>
                <MyExportCSV {...props.csvProps} />
                <BootstrapTable
                  bootstrap4
                  keyField="Id"
                  data={this.state.filesData}
                  columns={columns}
                  // filterPosition="top" 
                  filter={filterFactory()}
                  Comparator={filterFactory()}
                  pagination={paginationFactory({ sizePerPage: 50, sizePerPageList: sizePerPageList })}
                  selectRow={selectRow}
                  sort={{ dataField: 'Id', order: 'desc' }}
                  headerClasses="cust_header-class"
                  wrapperClasses="table-responsive margin10Bottom"
                  cellEdit={cellEditFactory({
                    mode: 'dbclick',
                    afterSaveCell: (oldValue, newValue, row, column) => {
                      var req = { newValue: newValue, req_no: row.Id, column: column.dataField };
                      console.log(req);
                      AxiosApi
                        .post(process.env.REACT_APP_API_ROOT_URL_PREFIX + "/api/update", req)
                        .then((res) => {
                          console.log(res);
                          toast.success("Updated Additional Notification for " + row.Id);
                        })
                        .catch((err) => {
                          console.log(err);
                          toast.error("Error in updating Additional Notification");
                        });
                      this.pullData();
                    },
                  })

                  }
                  {...props.baseProps} />

              </div>
            )}
        </ToolkitProvider>
        <ToastContainer />
        {this.state.loading && <ClipLoader loading={this.state.loaded} />}
      </div>
    );
  }
}

export default Dashbord;
