import { useState, useEffect, createRef, Fragment } from "react";
import { Button, CardBody, Row, Col } from 'reactstrap'
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import BootstrapTable from "react-bootstrap-table-next";
import { v4 as uuidv4 } from 'uuid';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getPaginationArray } from '../../utils/pagination';
import '../../assets/styles/table.css'

import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

//import AxiosApi from 'AxiosApi';
import AxiosApi from '../../Api'

const columns = [
  {
    dataField: 'SoldTo',
    text: 'Sold To',
    headerClasses: 'border-0 font-weight-bold',
    classes: 'border-0 py-2 align-middle text-400',
    // sort: true,
    filter: textFilter(
      {
        delay: 100,
      }
    )
  },
  {
    dataField: 'ShipTo',
    text: 'Ship To',
    headerClasses: 'border-0 font-weight-bold',
    classes: 'border-0 py-2 align-middle text-400',
    // sort: true,
    filter: textFilter({
      delay: 100,
    })
  },
  {
    dataField: 'SalesOrg',
    text: 'Sales Org',
    headerClasses: 'border-0 font-weight-bold',
    classes: 'border-0 py-2 align-middle text-400',
    filter: textFilter({
      delay: 100,
    })

  },
  {
    dataField: 'ExportingZone',
    text: 'Exporting Zone',
    headerClasses: 'border-0 font-weight-bold',
    classes: 'border-0 py-2 align-middle text-400',


    filter: textFilter({
      delay: 100,
    })
  },
  {
    dataField: 'SoldToName',
    text: 'Sold To Name',
    headerClasses: 'border-0 font-weight-bold',
    classes: 'border-0 py-2 align-middle text-400',

    filter: textFilter({
      delay: 100,
    })
  },

  {
    dataField: 'ShipToName',
    text: 'Ship To Name',
    headerClasses: 'border-0 font-weight-bold',
    classes: 'border-0 py-2 align-middle text-400',
    // sort: true,
    filter: textFilter({
      delay: 100,
    })
  },
  {
    dataField: 'ShipToCountry',
    text: 'Ship to country',
    headerClasses: 'border-0 font-weight-bold',
    classes: 'border-0 py-2 align-middle text-400',
    // sort: true,
    filter: textFilter({
      delay: 100,
    })
  },

  {
    dataField: 'Payer',
    text: 'Payer',
    headerClasses: 'border-0 font-weight-bold',
    classes: 'border-0 py-2 align-middle text-400',
    // sort: true
  },


  {
    dataField: 'PayerName',
    text: 'Payer Name',
    headerClasses: 'border-0 font-weight-bold',
    classes: 'border-0 py-2 align-middle text-400',
    // sort: true,
    filter: textFilter({
      delay: 100,
    })
  },

  {
    dataField: 'AreaManager',
    text: 'Area Manager',
    headerClasses: 'border-0 font-weight-bold',
    classes: 'border-0 py-2 align-middle text-400',
    // sort: true,
    filter: textFilter({
      delay: 100,
    })
  },

  {
    dataField: 'ImportingZone',
    text: 'Importing Zone',
    headerClasses: 'border-0 font-weight-bold',
    classes: 'border-0 py-2 align-middle text-400',
    // sort: true
  },

  {
    dataField: 'Attribute',
    text: 'Attribute',
    headerClasses: 'border-0 font-weight-bold',
    classes: 'border-0 py-2 align-middle text-400',
    // sort: true,

  },

]

const Customer = ({ salesOrganizationValue, setSalesOrganizationValue,
  soldToValue, setSoldToValue, shipToValue, setShipToValue, exportingZoneValue, setExportingZoneValue, }) => {
  const [customerData, setCustomerData] = useState([]);
  const dataMap = (element) => ({
    ...element,
    Attribute: element?.["Attribute 3rd party"],
    AreaManager: element?.["Area Manager"],
    ImportingZone: element?.["Importing zone"],
    PayerName: element?.["Payer name"],
    ShipToName: element?.["Ship to name"],
    ShipTo: element?.["Shipto"],
    SoldTo: element?.["Soldto"],
    SoldToName: element?.[" Sold to Name"],
    SalesOrg: element?.["Sales Org#"],
    ShipToCountry: element?.["Ship to country"],
    Payer: element?.["Payer"],
    ExportingZone: element?.["Flow"],
    id: uuidv4(),
  });
  const config2 = {
    method: "GET",
    url: process.env.REACT_APP_API_ROOT_URL_PREFIX + "/getCustomerList",
  };
  useEffect(
    () =>
      AxiosApi(config2)
        .then((response) => {
          setCustomerData(response?.data?.recordsets[0]);
        })
        .catch((error) => {
          console.log(error);
        }),
    []
  );

  const dataa = customerData instanceof Array && customerData?.map(dataMap);
  return (
    <CustomerTable data={dataa} />
  )
}
const CustomerTable = ({ data }) => {

  const options = {
    custom: true,
    sizePerPage: 10,
    totalSize: data.length,

  };
  let table = createRef();

  const [result, setResult] = useState([]);
  const [salesOrganizationValue, setSalesOrganizationValue] = useState('');
  const [soldToValue, setSoldToValue] = useState([]);
  const [shipToValue, setShipToValue] = useState([]);
  const [exportingZoneValue, setExportingZoneValue] = useState('');

  const select = (row) => {
    if (!result?.includes(row.id)) {
      setResult(result => [...result, row.id]);
      setSalesOrganizationValue((salesOrganizationValue) => [...salesOrganizationValue, row['SalesOrg']]);
      setShipToValue((shipToValue) => [...shipToValue, row['ShipTo']]);
      setSoldToValue((soldToValue) => [...soldToValue, row['SoldTo']]);

    }
    else {
      setResult((result) => result?.filter((item) => item !== row.id))
      setSalesOrganizationValue((salesOrganizationValue) => salesOrganizationValue.filter((item) => item !== row['SalesOrg']));
      setShipToValue((shipToValue) => shipToValue.filter((item) => item !== row['ShipTo']));
      setSoldToValue((soldToValue) => soldToValue.filter((item) => item !== row['SoldTo']));
      // setExportingZoneValue((exportingZoneValue) => exportingZoneValue.filter((item)=>item!==row['Flow']));
    }
    setExportingZoneValue(row.ExportingZone);
  }

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    onSelect: select
  };



  return (
    <>

      <BootstrapTable
        bootstrap4
        keyField="id"
        data={data}
        columns={columns}
        selectRow={selectRow}
        filter={filterFactory()}
        classes="table-dashboard table-striped table-sm fs--1 border-bottom border-200 mb-0 fontt"
        rowClasses="btn-reveal-trigger text-400 border-top  border-200"
        headerClasses="bg-200 text-700 border-y font-weight-bold border-200"
        pagination={paginationFactory()}

      />
      <div style={{ textAlign: 'left' }}>
        <h3>Selected Values:</h3>
        <div className="row">
          <div className="col-auto">
            <label className="form-label">Sales Organization</label>
          </div>
          <div className="col">
            <div className="form-group">
              <input type="text" className="form-control form-control-sm" value={salesOrganizationValue} />
            </div>
          </div>
          <div className="col-auto">
            <label className="form-label">Ship To</label>
          </div>
          <div className="col">
            <div className="form-group">
              <input type="text" className="form-control form-control-sm" value={shipToValue} />
            </div>
          </div>
          <div className="col-auto">
            <label className="form-label">Sold To</label>
          </div>
          <div className="col">
            <div className="form-group">
              <input type="text" className="form-control form-control-sm" value={soldToValue} />
            </div>
          </div>
          <div className="col-auto">
            <label className="form-label">Exporting Zone</label>
          </div>
          <div className="col">
            <div className="form-group">
              <input type="text" className="form-control form-control-sm" value={exportingZoneValue} />
            </div>
          </div>


        </div>

      </div>

    </>
  );
};
export default Customer;